import { createSlice } from "@reduxjs/toolkit";

const isMobileReducer = createSlice({
  name: "mobile",
  initialState: { isMobile: false }, 
  reducers: {
    setIsMobile: (state, action) => {
      state.isMobile = action.payload;
    },
  },
});

export const { setIsMobile } = isMobileReducer.actions;

export default isMobileReducer.reducer;
