import React from "react";
import { useRoutes } from "react-router";
import Router from "./routes/Router";
import { ThemeProvider } from "@mui/material";
import { ChatUVTheme } from "./style/ChatUVTheme";


function App() {
  const routing = useRoutes(Router);
  const ColorModeContext = React.createContext({ toggleColorMode: () => {} });

  const colorMode = React.useMemo(
    () => ({
      toggleColorMode: () => {
        // setMode((prevMode) => (prevMode === "light" ? "dark" : "light"));
      },
    }),
    []
  );



  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={ChatUVTheme}>{routing}</ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
