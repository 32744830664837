//mio
import {
  InputBase,
  IconButton,
  Box,
} from "@mui/material";
import {
  createTheme,
  alpha,
  styled,
} from "@mui/material/styles";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
const hoverPrimario = "#e83588";
const hoverSecundario = "#0F9FAF";
const mainColor = "#0F9FAF";
const mainColorTitulo = "#000";
const colorTexto2 = "#201A3E";
const colorGris = "#EDEDED";
const drawerWidth = 287;
const colorTextoClase = "#EDEDED";
const naranja = "#f2af00";
// const colorGloboChat = "#EDEDED";


export const getColor = () => {
  let color = {
    hoverPrimario: hoverPrimario,
    hoverSecundario: hoverSecundario,
    mainColor: mainColor,
    mainColorTitulo: mainColorTitulo,
    colorTexto2: colorTexto2,
    drawerWidth: drawerWidth,
    colorTextoClase: colorTextoClase,
    colorGris: colorGris,
    naranja: naranja,
  };
  return color;
};

export const ChatUVTheme = createTheme({
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: mainColor,
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      //light: "#000",
      main: "#000",
      // dark: will be calculated from palette.secondary.main,
      contrastText: "#ffcc00",
    },
    // Used by `getContrastText()` to maximize the contrast between
    // the background and the text.
    contrastThreshold: 3,
    // Used by the functions below to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2,
  },
  typography: {
    h5: {
      color: mainColorTitulo,
      fontWeight: 500,
    },
    h6: {
      color: colorTexto2,
      fontWeight: 400,
      fontSize: 16,
    },
    tituloAlert: {
      color: mainColorTitulo,
      fontWeight: 500,
      fontSize: 22,
    },
    body1: {
      fontSize: "16px",
      color: colorTexto2,
    },
    body4: {
      fontSize: "16px",
      color: colorTexto2,
    },
    body5: {
      fontSize: "18px",
      color: colorTexto2,
    },
    body6: {
      fontSize: "22px",
      color: colorTexto2,
    },
    fontIconButton: {
      fontSize: 16,
      color: mainColorTitulo,
      marginLeft: 10,
      fontWeight: 400,
    },
    allVariants: {
      color: colorTexto2,
    },
  },
  shadows: {
    ...createTheme({}).shadows.map((shadow, i) =>
      i === 1 ? "0 5px 8px 3px rgba(9, 32, 46, 0.06)" : shadow
    ),
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "46px",
          width: "auto",
          padding: "12px 18px 12px 18px",
          boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
          fontSize: "11pt",
          fontWeight: 400,
          textTransform: "none",
          "&:hover": {
            backgroundColor: hoverPrimario,
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: mainColor,
          "&:hover": {
            backgroundColor: hoverPrimario,
            color: "#fff",
            //agrega un efecto para el cambio del hover
            transition: "all 0.3s",
          },
          fontWeight: 400,
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: "19px",
        },
      },
    },
    MuiTypography: {
      // root: {
      //   fontFamily: "Poppins",
      // },
      variants: [
        {
          props: { variant: "titulo" },
          style: {
            color: mainColorTitulo,
            fontSize: "26px",
            fontWeight: 500,
          },
        },
        {
          props: { variant: "titulo_2" },
          style: {
            color: mainColorTitulo,
            fontSize: "30px",
            fontWeight: 500,
          },
        },
        {
          props: { variant: "titulo_3" },
          style: {
            color: mainColorTitulo,
            fontSize: "25px",
            fontWeight: 500,
          },
        },
        {
          props: { variant: "titulo_4" },
          style: {
            color: mainColorTitulo,
            fontSize: "18px",
            fontWeight: 600,
          },
        },
        {
          props: { variant: "titulo_5" },
          style: {
            color: mainColor,
            fontSize: "18px",
            fontWeight: 500,
            padding: 0,
            margin: 0,
          },
        },
        {
          props: { variant: "textoClase" },
          style: {
            color: "#3E3E3E",
            fontSize: "24px",
            fontWeight: 500,
          },
        },
        {
          props: { variant: "infoTextoRosa" },
          style: {
            color: mainColor,
            fontSize: "12px",
            fontWeight: 600,
            fontStyle: "italic",
          },
        },
      ],
    },
    CustomIconButton: {
      variants: [
        {
          props: { variant: "outlined" },
          style: {
            background: "transparent",
            color: mainColor,
            fontSize: "1.3em",
            boxShadow: "none",
            ":hover": {
              background: "#f0f0f0",
            },
            "& .bi": {
              width: "1em",
              height: "1em",
            },
          },
        },
        {
          props: { size: "large" },
          style: {
            fontSize: "1.8em",
          },
        },
      ],
    },
    MuiPopover: {
      styleOverrides: {
        paper: {
          borderRadius: "18px",
          background: "#fff",
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          "&:before": {
            display: "none",
          },
        },
      },
    },
  },
});

export const CustomInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(0),
  },
  "& .MuiInputBase-input": {
    borderRadius: "16pt",
    position: "relative",
    backgroundColor: theme.palette.mode === "light" ? "#f3f3f3" : "#2b2b2b",
    border: 0,
    fontSize: 13,
    width: "350px",
    padding: "15px 17px",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    "&:focus": {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.1rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}));

export const CustomInput2 = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: 10,
    position: "relative",
    backgroundColor: theme.palette.mode === "light" ? "#fff" : "#2b2b2b",
    border: 1,
    borderStyle: "solid",
    borderColor: "#555",
    fontSize: 13,
    color: "#555",
    fontWeight: 500,
    width: "447px",
    padding: "8px 10px",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    "&:focus": {
      boxShadow: `${alpha(theme.palette.primary.main, 0.15)} 0 0 0 0.05rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}));

export const CustomIconButton = styled(IconButton, {
  name: "CustomIconButton",
  slot: "Root",
})(({ theme }) => ({
  background: theme.palette.primary.main,
  boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
  color: theme.palette.primary.contrastText,
  ":hover": {
    background: hoverPrimario,
  },
}));

export const BoxModalChatBox= styled(Box)((theme) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  padding: 24,
  // maxHeight: 1050,
  maxHeight: "80vh" || 1050,
  minHeight: 250,
  backgroundColor: "#fff",
  borderRadius: "18px",
  minWidth: 250,
  maxWidth: 1600,
  overflowY: "auto",
}));

export const SuccessActionModal = () => {
  return (
    <CheckCircleOutlineOutlinedIcon
      sx={{ color: mainColor, fontSize: "4rem", marginBottom: "25px" }}
    />
  );
};
export const ErrorActionModal = () => {
  return (
    <CancelOutlinedIcon
      sx={{ color: mainColor, fontSize: "4rem", marginBottom: "25px" }}
    />
  );
};
