import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
  name: "sidebar",
  initialState: { openSideBar: false }, 
  reducers: {
    setOpenSideBar: (state, action) => {
      state.openSideBar = action.payload;
    },
  },
});

export const { setOpenSideBar } = userSlice.actions;

export default userSlice.reducer;
