import { createSlice } from "@reduxjs/toolkit";

const changeSessionIDReducer = createSlice({
  name: "sessionID",
  initialState: { changeSessionID: null },
  reducers: {
    setChangeSessionID: (state, action) => {
      state.changeSessionID = action.payload;
    },
  },
});

export const { setChangeSessionID } = changeSessionIDReducer.actions;

export default changeSessionIDReducer.reducer;
