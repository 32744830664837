import { createSlice } from "@reduxjs/toolkit";

const varSession = createSlice({
  name: "varSession",
  initialState: { disNewChat: true, chatsList: [], isEmptyChatDelete: false },
  reducers: {
    setDisNewChat: (state, action) => {
      state.disNewChat = action.payload;
    },
    setChatsList: (state, action) => {
      state.chatsList = action.payload;
    },
    setIsEmptyChatDelete: (state, action) => {
      state.isEmptyChatDelete = action.payload;
    },
  },
});

export const { setDisNewChat, setChatsList, setIsEmptyChatDelete } =
  varSession.actions;

export default varSession.reducer;
