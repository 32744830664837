import { configureStore } from "@reduxjs/toolkit";
import sideBarReducer from "./redux/sideBarSlice";
import isMobileReducer from "./redux/movileSlice";
import changeSessionIDReducer from "./redux/changeSessionID";
import varSessionReducer from "./redux/varSession";

export default configureStore({
  reducer: {
    sidebar: sideBarReducer,
    isMobile: isMobileReducer,
    changeSessionID: changeSessionIDReducer,
    varSession: varSessionReducer,
  },
});
